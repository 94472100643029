import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import LogoSvg from "../../assets/image/svg/logo.svg";

const LogoImg = styled.img`
  height: 30px;
`;

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <LogoImg alt="logo" src={LogoSvg} />
    </Link>
  );
};

export default Logo;
