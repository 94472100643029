import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import { Link } from "gatsby";

import Logo from "../Logo";

import DiscordImg from "../../assets/image/svg/discord.svg";
import TelegramImg from "../../assets/image/svg/telegram.svg";
import GithubImg from "../../assets/image/svg/github.svg";
import TwitterImg from "../../assets/image/svg/twitter.svg";

const TitleStyled = styled(Title)`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const LogoComment = styled(Title)`
  font-size: 14px;
  color: #49494a;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin: 24px 0;
  line-height: 1.5;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 16px;
    line-height: 2.5;
    color: #49494a;
    font-weight: 400 !important;
    a {
      padding: 0 !important;
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  padding: 0 0 100px 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    li {
      a {
        margin-right: 28px;
        transition: 0.4s;
        &:visited {
          text-decoration: none;
        }
        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
      &::last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 0;
            `}
          >
            <Row className="justify-content-between">
              <Col lg="5" md="4">
                <Logo white={isDark} />
                <LogoComment>The global layer for NFTs exchange</LogoComment>
              </Col>
              <Col lg="7" md="8" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Platform
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="https://www.gameswap.org" target="_blank">
                            Gameswap
                          </a>
                        </li>
                        <li>
                          <a href="https://www.coingecko.com/en/coins/niftyx-protocol" target="_blank">
                            Token
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        About
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        {/* <li>
                          <Link to="/faq">FAQ</Link>
                        </li> */}
                        <li>
                          <a href="mailto:info@niftyx.org">Jobs</a>
                          {/* <Link to="/career">Jobs</Link> */}
                        </li>
                        <li>
                          <Link to="/terms-conditions">Terms of Service</Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Comms
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="https://niftyx.medium.com/" target="_blank">
                            Blog
                          </a>
                        </li>
                        <li>
                          <a href="mailto:info@niftyx.org">Contact</a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <ul className="social-icons">
              {/* <li>
                <a href="https://discord.com/invite/eZcFahS" target="_blank">
                  <img alt="icon" src={DiscordImg} />
                </a>
              </li> */}
              <li>
                <a href="https://t.me/NiftyxProtocol" target="_blank">
                  <img alt="icon" src={TelegramImg} />
                </a>
              </li>
              <li>
                <a href="https://github.com/Niftyx" target="_blank">
                  <img alt="icon" src={GithubImg} />
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/NiftyxDAO" target="_blank">
                  <img alt="icon" src={TwitterImg} />
                </a>
              </li>
            </ul>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
