export const menuItems = [
  // {
  //   name: "about",
  //   label: "About",
  // },
  {
    name: "https://twitter.com/NiftyxProtocol",
    label: "Twitter",
    isExternal: "true"
  },
  {
    name: "https://t.me/niftyxprotocol",
    label: "Telegram",
    isExternal: true,
  },
];
